import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {CancelToken} from 'axios';
import ChallengeWindow from '../../../components/ChallengeWindow';
import ChallengeBox from '../../../components/ChallengeBox';
import LoadingMessage from '../../../components/LoadingMessage';
import axios from '../../../utils/axios';
import config from '../../../utils/config.json';
import './Challenges.css';

let source;

function getChallenges() {
  return new Promise((resolve, reject) => {
    axios.get(`${config.apiUrl}/challenges/2022`, {cancelToken: source.token})
      .then(res => {
        if (res.status !== 200) {
          return reject("Error searching for challenges");
        }
        return resolve(res.data);
      })
      .catch((error) => {
        return reject(error);
      })
  })
}

class Challenges extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: '',
      challenges: null
    }
    source = CancelToken.source();
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    getChallenges()
      .then((result) => {
        this.setState({
          challenges: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);          
        }
      });
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Unmounted component");
    }
  }

  handleClick = (num,year,completed) => {
    this.setState({
      selected: num,
      
      completed: completed
    });
  };

  handleClose = () => {
    this.setState({
      selected: '',
      completed: false,
    });
    getChallenges()
      .then((result) => {
        this.setState({
          challenges: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);          
        }
      });
  };

  render() {
    const {
      challenges,
      completed,
      selected,
    } = this.state;
    const {
      auth,
      updateUnreadMessage,
    } = this.props;
    return (
      <Container fluid className="challenges">
      {selected ? (
        <ChallengeWindow
          auth={auth}
          updateUnreadMessage={updateUnreadMessage}
          onClose={this.handleClose}
          num={selected} 
          completed={completed} />
      ) : (
        <Row>
          <Col xs={12}>
          {challenges && challenges.map((challenge, index, a) => (
            <Row key={challenge.number}>
            <Col xs={4}>
              <ChallengeBox 
                title={challenge.title}
                num={challenge.number}
                year={2022}
                value={challenge.value} 
                completed={challenge.completed}
                onClick={this.handleClick}/>
            </Col>
            </Row>
          ))}
          </Col>
        </Row>
      )}
      {(challenges && challenges.length == 0)
        ? (
          <Container fluid className="EmptyMessage">
            <Row>
              <Col xs={12} md={{span: 10, offset: 1}}>
                <div className="announcement">
                  <p>
                    Challenges aren't available yet, come back later!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        )
        : ''}
      {!selected && !challenges
        ? (
          <LoadingMessage />
        )
        : ''}
      </Container>
    );
  }
}

export default Challenges;
